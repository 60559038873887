import { TFunction } from 'i18next';

import {
  Contract,
  SubscriberData
} from 'shared/blocks/new-selfcare/shared/hooks/useSubscriberDataQuery';
import getContractLabel from '../../shared/helpers/getContractLabel';

export default function subscriberDataFormatterI18n(
  subscriberData: SubscriberData,
  t: TFunction
) {
  const listOfContracts: Contract[] = subscriberData?.listOfContracts ?? [];

  return {
    name: subscriberData?.firstName ?? '',
    numSubscriber: subscriberData?.numSubscriber ?? '',
    numContract: subscriberData?.numContract ?? null,
    contractOptions: listOfContracts.map(
      ({ numContract: value, ...contract }: Contract) => ({
        label: `${getContractLabel(contract)} - ${t(
          'blocks.selfcare.welcome.contract'
        )}${value}`,
        value
      })
    )
  };
}
