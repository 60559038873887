import React, { useEffect, useMemo, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Select from 'shared/components/presentational/Select';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import Error from 'shared/components/presentational/Error/Error';
import Loader from '../../shared/components/Loader';
import { subscriberDataFormatterI18n } from '../helpers';
import { Props } from '../types';
import usePrevious from '../../../../helpers/react/usePrevious';

/*
 * Main wrapper should be a section
 * in order to inherit margins
 */
const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  ${mediaQueries.toTablet`
      max-width: 100%;
      margin: 0 15px;
  `};
`;

const Title = styled.h1`
  color: var(--text-title);
  font-size: ${pxInRem(30)};
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.bold};
  text-transform: uppercase;
`;

const Subtitle = styled.p`
  color: var(--text-light);
  text-align: center;
  font-family: ${FONT_FAMILIES.base};
`;

const NumberSubscriber = styled.strong`
  color: var(--text-default);
`;

const SelectWrapper = styled.div`
  margin-top: 15px;
  width: 100%;
  max-width: 340px;
  text-transform: uppercase;

  .ReactSelect {
    &__control {
      cursor: pointer;
      min-height: 56px;
    }

    &__single-value {
      padding-left: 10px;
    }
  }

  .Select-clear-zone {
    display: none;
  }

  .Select-value {
    display: flex;
    align-items: center;
    padding-top: 0;

    .Select-value-label {
      padding-top: 0;
    }
  }

  .Select-input > input {
    padding: 24px 20px 6px;
  }
`;

const ErrorWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

export default function Welcome({
  title,
  displayBlock,
  displayContractSelectList,
  numContract,
  onUpdateNumContract,
  subscriberData: {
    data: subscriberData,
    error: subscriberDataError,
    isLoading: subscriberDataIsLoading,
    isIdle: subscriberDataIsIdle,
    failureCount: subscriberDataFailureCount
  }
}: Props) {
  const { t } = useTranslation();
  const previousNumContract = usePrevious(numContract);

  const formattedSubscriberData = useMemo(() => {
    if (!subscriberData) {
      return undefined;
    }

    return subscriberDataFormatterI18n(subscriberData, t);
  }, [subscriberData, t]);

  const value = useMemo(() => {
    return formattedSubscriberData?.contractOptions.find(
      contract => contract.value === numContract
    );
  }, [formattedSubscriberData?.contractOptions, numContract]);

  const handleContractChange = useCallback(
    (contractNumber: string | null) => {
      if (!contractNumber) {
        return;
      }

      onUpdateNumContract(contractNumber);
    },
    [onUpdateNumContract]
  );

  useEffect(() => {
    if (formattedSubscriberData && numContract !== previousNumContract) {
      const {
        numContract: defaultNumContract,
        contractOptions
      } = formattedSubscriberData;

      const contractExist = contractOptions.find(
        contract => contract.value === numContract
      );

      if (!contractExist || !numContract) {
        handleContractChange(defaultNumContract);
      }
    }
  }, [formattedSubscriberData, numContract, handleContractChange]);

  const handleSelect = useCallback(
    (option: { value: string }) => {
      if (!option) {
        return;
      }

      onUpdateNumContract(option.value);
    },
    [onUpdateNumContract]
  );

  if (!displayBlock) {
    return null;
  }

  if (subscriberDataError?.prospectMessage) {
    return (
      <ErrorWrapper>
        <Error message={subscriberDataError?.prospectMessage} />
      </ErrorWrapper>
    );
  }

  if (
    subscriberDataError &&
    subscriberDataFailureCount &&
    subscriberDataFailureCount > 1
  ) {
    return (
      <ErrorWrapper>
        <Error />
      </ErrorWrapper>
    );
  }

  if (
    subscriberDataIsLoading ||
    subscriberDataIsIdle ||
    subscriberDataError ||
    !formattedSubscriberData
  ) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Subtitle>
        <Trans
          i18nKey="blocks.selfcare.welcome.subtitle"
          values={{
            name,
            numSubscriber: formattedSubscriberData.numSubscriber
          }}
          // eslint-disable-next-line react/jsx-key
          components={[<NumberSubscriber />]}
        />
      </Subtitle>

      {displayContractSelectList &&
        formattedSubscriberData.contractOptions.length > 0 && (
          <SelectWrapper>
            <Select
              isClearable={false}
              isSearchable={false}
              // @ts-ignore
              onChange={handleSelect}
              options={formattedSubscriberData.contractOptions}
              value={value}
            />
          </SelectWrapper>
        )}
    </Wrapper>
  );
}
