import { createSelector } from 'reselect';

import getPageData from '../getPageData';
import getCurrentZone from '../getCurrentZone';
import getLoginUrlFromPageWithFallback from '../../helpers/getLoginUrlFromPageWithFallback';

export default createSelector(
  [getPageData, getCurrentZone],
  getLoginUrlFromPageWithFallback
);
