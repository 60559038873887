import { connect } from 'react-redux';
import { compose } from 'recompose';

import getNumContract from '../../shared/selectors/getNumContract';
import Welcome from '../components/Welcome';
import withSubscriberDataQuery from 'shared/blocks/new-selfcare/shared/hocs/withSubscriberDataQuery';
import {
  PropsFromState,
  PropsFromDispatch,
  Props,
  FormattedProps
} from '../types';
import { setNumContract } from '../../shared/actions/setNumContract';

const mapStateToProps = (state: any): PropsFromState => {
  const numContract = getNumContract(state);

  return {
    numContract
  };
};

const mapDispatchToProps = (dispatch: any): PropsFromDispatch => ({
  onUpdateNumContract: (numContract: string) =>
    dispatch(setNumContract(numContract))
});

export default compose<Props, FormattedProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withSubscriberDataQuery
)(Welcome);
