import React, { ComponentType } from 'react';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import { SelfcareApis } from 'shared/modules/page/selectors/getSelfcareApis';
import useSubscriberDataQuery from '../hooks/useSubscriberDataQuery';
import getPathForLocation from 'shared/modules/tasks/helpers/getPathForLocation';
import getSilentLoginUrl from 'shared/modules/page/selectors/authentication/getSilentLoginUrl';
import getLocation from 'shared/modules/router/selectors/getLocation';
import getLoginUrlWithFallback from 'shared/modules/page/selectors/authentication/getLoginUrlWithFallback';
import createSilentLoginRedirectUrl from 'shared/modules/page/helpers/createSilentLoginRedirectUrl';
import getNumContract from 'shared/blocks/selfcare/shared/selectors/getNumContract';
import { setNumContract } from 'shared/blocks/selfcare/shared/actions/setNumContract';
export interface WithSubscriberDataProps {
  subscriberData: Partial<ReturnType<typeof useSubscriberDataQuery>>;
}

function mapStateToProps(state: any) {
  const currentPath = getPathForLocation(getLocation(state));
  const silentLoginUrl = getSilentLoginUrl(state);
  const loginUrl = getLoginUrlWithFallback(state);
  // redirect to silent login page
  const silentLoginRedirectUrl = createSilentLoginRedirectUrl(
    silentLoginUrl,
    loginUrl,
    currentPath
  );
  const numContract = getNumContract(state);
  return {
    silentLoginRedirectUrl,
    numContract
  };
}

const mapDispatchToProps = {
  push,
  setNumContract
};
const connectComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
  (
    { silentLoginRedirectUrl, numContract: currentNumContract },
    { push, setNumContract },
    ownProps
  ) => ({
    redirectToSilentLogin: () => {
      push(silentLoginRedirectUrl);
    },
    setNumContractIfNeeded: (numContract: string) => {
      if (!currentNumContract) {
        setNumContract(numContract);
      }
    },
    ...ownProps
  })
);

export default function withSubscriberDataQuery<
  T extends {
    apis: Pick<SelfcareApis, 'getSubscriberData'>;
    redirectToSilentLogin: () => void;
    setNumContractIfNeeded: (numContract: string) => void;
  }
>(
  Component: ComponentType<
    Omit<T, 'redirectToSilentLogin' | 'setNumContractIfNeeded'> &
      WithSubscriberDataProps
  >
) {
  function ComponentWithSubscriberDataQuery({
    redirectToSilentLogin,
    setNumContractIfNeeded,
    ...props
  }: T) {
    const subscriberData = useSubscriberDataQuery(
      props.apis,
      redirectToSilentLogin,
      setNumContractIfNeeded
    );

    return <Component subscriberData={subscriberData} {...props} />;
  }

  // @ts-ignore
  return connectComponent(ComponentWithSubscriberDataQuery);
}
